
import { Component, Vue, Watch } from "vue-property-decorator";
import data from "./assets/data2.json"
import ilesName from "./assets/ilesNames.json"
import Menu from "./components/Menu.vue"
import ManageHistory from "./components/ManageHistory.vue"
import PartyPopup from "./components/PartyPopup.vue"
import HistoryElem from "./components/HistoryElem.vue"
import Success from "./components/Success.vue"
import "./css/global.scss";
import * as L from 'leaflet';
import * as shuffleSeed from 'shuffle-seed';

@Component({
    components: {
        Menu,
        PartyPopup,
        Success,
        ManageHistory
    },
})
export default class App extends Vue {

    private m_markerList : any[] = []
    private m_lineList : any[] = []

    private m_mode = "daily";
    
    private m_randomGames: any[] = [];
    private m_rectangleList :any[] = [];
    private m_currentIle :any = null;
    private m_streakAnwser :any[] = [];
    private m_streakCount = 0;
    private m_hasGuess = false;
    private m_hasLooseStreak = false;

    private m_marker;
    private m_line;
    private m_map;

    private m_dateKey = "";
    private m_dateStr = "";
    private m_menuKey=0;

    private m_showBigger = false;
    private m_history;
    private m_streakHistory;
    private m_answerCoord;
    private m_answerMaker;
    private m_hasPicked = false;
    private m_showMap = false;
    private m_showResult = false;
    private m_currentMapIndex = 0;
    private m_totalScore = 0;
    private m_progressbarTotalScore = 0;
    private m_showRecap = false;

    private m_increaseSize = false;
    private m_canvas;
    private m_ctx;
    private m_lastX;
    private m_lastY;
    private m_dragStart;
    private m_dragged;
	private m_bgImage = new Image;

    private m_currentZoom = 1;
    
    private m_distance = -1;
    private k_maxDistance = 4000;
    private k_minDistance = 15;
    private m_score = 0;
    private m_isLoading = true;

    private m_countDown = "";

    private m_answers :any[] = [];
    
    private m_manageHistory = false;
    private m_badgeShow;

    private m_showPartyPopup = false;
    private m_seed : string | null = "";

    private m_showClipboard = false;

    private m_icon = new L.Icon({
        iconUrl: './marker.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    private increaseMapSize() {
        this.m_increaseSize = true;

        setTimeout(() => {
            this.m_map.invalidateSize()
        },200);
    }

    private initStreakMode(){
        ilesName["ilesName"].forEach(_element => {
            const lRectangle = L.rectangle(_element['coordinate'], {color: '#0000FF00', weight: 1}).on('click', (e) =>  {
                if (!this.m_hasGuess) {
                    this.m_currentIle = lRectangle;
                    this.m_rectangleList.forEach(_rectangle => {
                        _rectangle.setStyle({color: "#0000FF00"});
                    })
                    lRectangle.setStyle({color: '#0000FF'})
                }
            });
            this.m_rectangleList.push(lRectangle)
            lRectangle.addTo(this.m_map);
        });
        this.restartStreak();
    }

    private initDailyMode() {
        
        if (this.m_map != null) {
            this.m_map.setView([-400, 400], 0);
            this.m_map.on('click', this.addMarker);
            document.getElementsByClassName('leaflet-control-zoom')[0].classList.add("hide");
        }
        
        this.getDate().then(() => {

            if (this.m_dateKey in this.m_history) {
                this.m_currentMapIndex = this.m_history[this.getDateKey()].length;
                if (this.m_currentMapIndex == 3) {
                    this.showRecap()
                } else {
                    for (let i = 0; i < this.m_history[this.m_dateKey].length; i++) {
                        this.m_totalScore += this.getScore(this.m_history[this.m_dateKey][i]['coord'], data[this.m_dateKey][i])
                    }
                }
            }
            if (!this.m_showRecap) {
                this.m_bgImage.src = './maps/' + this.getDateKey() + '_' + this.m_currentMapIndex + '.png';
                this.m_bgImage.onload = () => {
                    this.m_isLoading = false;
                    this.redraw();   
                }
                const lCoord = data[this.m_dateKey][this.m_currentMapIndex]
                this.m_answerCoord = {lat: lCoord[0], lng: lCoord[1]};
                this.m_answerMaker = new L.marker(this.m_answerCoord, {icon: this.m_icon})
                this.m_markerList.push(this.m_answerMaker);
            }
        })
    }

    private startParty(_seed){
        this.m_seed = _seed;
        this.m_mode = "streak";
        this.resetAllMode();
        this.clearMap();
        this.m_showPartyPopup = false;
        this.initStreakMode();
    }

    private clearMap( ){
        this.m_map.removeEventListener('click');
        this.m_rectangleList.forEach(_element => {
            _element.removeEventListener('click');
            this.m_map.removeLayer(_element)
        })
        this.m_markerList.forEach((_layer) => {
            this.m_map.removeLayer(_layer)
        })
        this.m_lineList.forEach((_layer) => {
            this.m_map.removeLayer(_layer)
        })
        this.redraw();
    }

    private resetAllMode() {
        this.m_showRecap = false;
        this.m_hasGuess = false;
        this.m_hasPicked = false;
        this.m_streakCount = 0;
        this.m_currentIle = null;
        this.m_showResult = false;
    }

    private switchMode() {
        this.resetAllMode();
        this.clearMap();
        if (this.m_mode == "daily"){
            this.m_mode = "streak";
            this.initStreakMode();
        }
        else{
            this.m_mode = "daily";
            this.m_seed = "";
            this.initDailyMode();
        }
    }
    
    private mounted() {
        //localStorage.removeItem("history")
        if (this.$route.query.seed && this.$route.query.seed != null){
            this.m_mode = "streak";
            this.m_seed = this.$route.query.seed as string;
        }
        
        this.m_dateKey = this.getDateKey();
        this.m_history = localStorage.getItem('history');
        if (this.m_history == null || this.m_history == undefined) {
            localStorage.setItem('history',JSON.stringify({}))
            this.m_history = {}
        } else {
            this.m_history = JSON.parse(this.m_history)
        }

        
        this.m_streakHistory = localStorage.getItem('streak');
        if (this.m_streakHistory == null || this.m_streakHistory == undefined) {
            localStorage.setItem('streak', "0")
            this.m_streakHistory = 0
        } else {
            this.m_streakHistory = parseInt(this.m_streakHistory)
        }

        this.m_canvas = document.getElementById('canvas');	
        this.m_canvas.width = window.innerWidth;
        this.m_canvas.height = window.innerHeight - 125;
        this.m_ctx = this.m_canvas.getContext('2d');

        this.m_map = L.map('map', {
            crs: L.CRS.Simple,
            bounds: new L.LatLngBounds([-1024, 0], [0, 1024]),
        });

        L.tileLayer('./map/{z}/{x}_{y}.png', {
            minZoom: 0,
            maxZoom: 6,
            tileSize: 1024,
            bounds: new L.LatLngBounds([-1024, 0], [0, 1024]),
            format: 'image/png',
        }).addTo(this.m_map);

        const lIlesMarker = new L.FeatureGroup();
        ilesName["ilesName"].forEach(_element => {
            const lIleMarker = new L.Marker(_element["coord"], {
            icon: new L.DivIcon({
                className: 'my-div-icon',
                html: '<div class="ile-name">' + _element['name'] + '</div>'
            })});
            this.m_markerList.push(lIleMarker);
            lIlesMarker.addLayer(lIleMarker);
        });

        this.m_map.on('zoomend', () => {
            if (this.m_map.getZoom() < 2){
                this.m_map.removeLayer(lIlesMarker);
            }
            else {
                this.m_map.addLayer(lIlesMarker);
            }
        });

        if (this.m_mode == "daily"){
            this.initDailyMode();
        }
        else{
            this.initStreakMode();
        }
        
        if(!this.m_showMap){
            window.onload = () => {
                this.trackTransforms(this.m_ctx);

                this.m_lastX=this.m_canvas.width/2;
                this.m_lastY=this.m_canvas.height/2;

                this.m_canvas.addEventListener('mousedown',(evt) => {
                    this.m_lastX = evt.offsetX || (evt.pageX - this.m_canvas.offsetLeft);
                    this.m_lastY = evt.offsetY || (evt.pageY - this.m_canvas.offsetTop);
                    this.m_dragStart = this.m_ctx.transformedPoint(this.m_lastX,this.m_lastY);
                    this.m_dragged = false;
                },false);            

                this.m_canvas.addEventListener('mousemove',(evt) => {
                    if (this.m_dragStart) {
                        this.m_lastX = evt.offsetX || (evt.pageX - this.m_canvas.offsetLeft);
                        this.m_lastY = evt.offsetY || (evt.pageY - this.m_canvas.offsetTop);
                        this.m_dragged = true;
                        const lPoint = this.m_ctx.transformedPoint(this.m_lastX,this.m_lastY);
                        this.m_ctx.translate(lPoint.x-this.m_dragStart.x,lPoint.y-this.m_dragStart.y);
                        
                        this.redraw();
                    }
                }, false);
                
                this.m_canvas.addEventListener('touchstart',(evt) => {
                    this.m_lastX = evt.changedTouches[0].clientX;
                    this.m_lastY = evt.changedTouches[0].clientY;
                    this.m_dragStart = this.m_ctx.transformedPoint(this.m_lastX,this.m_lastY);
                    this.m_dragged = false;
                },false);
                    
                
                this.m_canvas.addEventListener('touchmove',(evt) => {
                    this.m_lastX = evt.changedTouches[0].clientX;
                    this.m_lastY = evt.changedTouches[0].clientY;
                    this.m_dragged = true;
                    if (this.m_dragStart) {
                        const lPoint = this.m_ctx.transformedPoint(this.m_lastX,this.m_lastY);
                        this.m_ctx.translate(lPoint.x-this.m_dragStart.x,lPoint.y-this.m_dragStart.y);
                        this.redraw();
                    }
                }, false);

                const scaleFactor = 1.1;

                const zoom = (clicks) => {
                    const pt = this.m_ctx.transformedPoint(this.m_lastX,this.m_lastY);
                    this.m_ctx.translate(pt.x,pt.y);
                    const factor = Math.pow(scaleFactor,clicks);
                    
                    this.m_ctx.scale(factor,factor);
                    this.m_ctx.translate(-pt.x,-pt.y);
                    this.redraw();
                }

                this.m_canvas.addEventListener('mouseup',(evt) => {
                    this.m_dragStart = null;
                    if (!this.m_dragged) 
                    {   
                        zoom(evt.shiftKey ? -1 : 1 );
                    }
                },false);

                const handleScroll = function(evt){
                    const delta = evt.wheelDelta ? evt.wheelDelta/40 : evt.detail ? -evt.detail : 0;
                    if (delta) {
                        zoom(delta);
                    }
                    return evt.preventDefault() && false;
                };
            
                this.m_canvas.addEventListener('DOMMouseScroll',handleScroll,false);
                this.m_canvas.addEventListener('mousewheel',handleScroll,false);

                zoom(-4)
                this.redraw()
            }

        }
    }

    private checkStreakAnwser(){
        this.m_hasGuess = true;
        let lAnswer: any;

        this.m_currentIle.setStyle({color: "#FF0000"});
        this.m_rectangleList.forEach(_rectangle => {
            if (_rectangle.getBounds().contains(this.m_streakAnwser)){
                _rectangle.setStyle({color: "#00FF00"});
                lAnswer = _rectangle;
            }
        })
        
        if (this.m_currentIle['_leaflet_id'] == lAnswer['_leaflet_id']){
            this.m_streakCount++;
        }
        else {
            this.m_hasLooseStreak = true;
            this.m_showRecap = true;
            this.m_showMap = false;
            this.m_menuKey++;
        }
        
        if (this.m_seed == '' && this.m_streakCount > this.m_streakHistory) {
            this.m_streakHistory = this.m_streakCount;
            localStorage.setItem('streak', this.m_streakHistory.toString())
        }

        setTimeout( () => {
            this.m_map.invalidateSize(true);
            this.m_map.setView(this.m_streakAnwser, 2);
        }, 200);
    }

    private generateRandomGames(){
        const lRandom :any[] = []
        
        Object.keys(data).forEach((_key) => {
           // console.log(data[_key])
            for (let i = 0; i < data[_key].length; i++){
                const  lSplit = _key.split("-");
                if (parseInt(lSplit[1]) >= 7 && parseInt(lSplit[2]) >=2){
                    lRandom.push([_key + "_" + i, data[_key][i]])
                }
            }
        });
        
        if (this.m_seed != ''){
            this.m_randomGames = shuffleSeed.shuffle(lRandom, this.m_seed);
        }
        else{
            this.m_randomGames = lRandom.sort(() => Math.random() - 0.5);
        }
    }

    private restartStreak(_force = false) {
        this.m_streakCount = 0;
        this.m_showMap = false;
        this.m_hasLooseStreak = false;
        this.m_showRecap = false;
        this.generateRandomGames();
        this.startStreak();
    }

    private startStreak() {
        
        if (this.m_streakCount % this.m_randomGames.length == 0){
            this.generateRandomGames();
        }

        this.m_hasGuess = false;
        this.m_currentIle = null;
        this.m_isLoading = true;
        this.m_bgImage.src = './maps/' + this.m_randomGames[this.m_streakCount % this.m_randomGames.length][0] + '.png';
        this.m_bgImage.onload = () => {
            this.m_isLoading = false;
            this.redraw();   
        }
        this.m_rectangleList.forEach(_rectangle => {
            _rectangle.setStyle({color: "#00000000"});
        })
        this.m_streakAnwser = this.m_randomGames[this.m_streakCount % this.m_randomGames.length][1];
        setTimeout(() => {
            this.m_map.invalidateSize(true);
            this.m_map.setView([-400, 400], 0);
        }, 300);
    }

    private zoomImage(_value) {
        this.m_ctx.scale.call(this.m_ctx, _value, _value);
        this.redraw();
    }

    private redraw() {
        if (!this.m_showRecap){
            const p1 = this.m_ctx.transformedPoint(0,0);
            const p2 = this.m_ctx.transformedPoint(this.m_canvas.width, this.m_canvas.height);
            this.m_ctx.clearRect(p1.x,p1.y,p2.x-p1.x,p2.y-p1.y);

            this.m_ctx.save();
            this.m_ctx.setTransform(1,0,0,1,0,0);
            this.m_ctx.clearRect(0,0,this.m_canvas.width,this.m_canvas.height);
            this.m_ctx.restore();
            this.m_ctx.drawImage(this.m_bgImage,this.m_canvas.width / 2 - this.m_bgImage.width/2, this.m_canvas.height / 2 - this.m_bgImage.height/2);
        }
    }

    private trackTransforms(ctx) {
        const svg = document.createElementNS("http://www.w3.org/2000/svg",'svg');
        let xform : DOMMatrix | undefined = svg.createSVGMatrix();
        ctx.getTransform = function(){ return xform; };

        const savedTransforms:DOMMatrix[] = [];
        const save = ctx.save;
        ctx.save = function(){

            if (xform){
                savedTransforms.push(xform.translate(0,0));
            }
            return save.call(ctx);
        };
        
        const restore = ctx.restore;
        ctx.restore = function(){
            xform = savedTransforms.pop();
            return restore.call(ctx);
                };

        const scale = ctx.scale;
        ctx.scale = (sx,sy) => {
            if (xform) {
                if (xform && sx < 1 && xform.a <= 0.5) {
                    return;
                }
                if (xform && sx > 1 && xform.a >= 1.3) {
                    return;
                }
                xform = xform?.scaleNonUniform(sx,sy);
                this.m_currentZoom = xform.a;
                return scale.call(ctx,sx,sy);
                }
            return;
        };
        
        const rotate = ctx.rotate;
        ctx.rotate = function(radians){
            xform = xform?.rotate(radians*180/Math.PI);
            return rotate.call(ctx,radians);
        };
        
        const translate = ctx.translate;
        ctx.translate = function(dx,dy){
            xform = xform?.translate(dx,dy);
            return translate.call(ctx,dx,dy);
        };
        
        const transform = ctx.transform;
        ctx.transform = function(a,b,c,d,e,f){
            const m2 = svg.createSVGMatrix();
            m2.a=a; m2.b=b; m2.c=c; m2.d=d; m2.e=e; m2.f=f;
            xform = xform?.multiply(m2);
            return transform.call(ctx,a,b,c,d,e,f);
        };
        
        const setTransform = ctx.setTransform;
        ctx.setTransform = function(a,b,c,d,e,f){
            if (xform) {
                xform.a = a;
                xform.b = b;
                xform.c = c;
                xform.d = d;
                xform.e = e;
                xform.f = f;
            }
            return setTransform.call(ctx,a,b,c,d,e,f);
        };

        const pt  = svg.createSVGPoint();
        ctx.transformedPoint = function(x,y){
            pt.x=x; pt.y=y;
            return pt.matrixTransform(xform?.inverse());
        }
    }

    private nextMap(){
        this.m_currentMapIndex++;

        this.m_map.setView([-400, 400], 0);
        this.m_map.removeLayer(this.m_answerMaker)
        this.m_map.removeLayer(this.m_marker)
        this.m_map.removeLayer(this.m_line)
        this.m_line = null;
        const lCoord = data[this.m_dateKey][this.m_currentMapIndex];
        this.m_answerCoord = {lat: lCoord[0], lng: lCoord[1]};
        this.m_answerMaker = new L.marker(this.m_answerCoord, {icon: this.m_icon})
        this.m_markerList.push(this.m_answerMaker);

        this.m_distance = -1;
        this.m_showResult = false;
        this.m_hasPicked = false;
        this.m_bgImage.src = './maps/' + this.getDateKey() + '_' + this.m_currentMapIndex +'.png';
        this.m_isLoading = true;
        this.m_bgImage.onload = () => {
            this.m_isLoading = false;
            this.redraw();   
        }
    }

    private isCoordinateValide(_latlng){
        const x = _latlng.lng
        const y = _latlng.lat * -1;

        if (x < 0 || x > 1024 || y < 0 || y > 1024){
            return false;
        }
        return true;
    }

    private drawLine() {
        const latlngs: any[] = [] 

        latlngs.push(this.m_marker.getLatLng());
        latlngs.push(this.m_answerMaker.getLatLng());

        this.m_line = L.polyline(latlngs, {color: 'black'}).addTo(this.m_map);
        this.m_lineList.push(this.m_line);

        this.m_map.fitBounds(this.m_line.getBounds());
    }

    private getDate() {
        return new Promise<void>( (_resolve, _reject) => {
            fetch("https://worldtimeapi.org/api/timezone/Europe/Paris.json").then((_response) => {
                return _response.json();
            }).then((data) => {
                this.m_dateStr = data['utc_datetime']
                _resolve()
            }).catch((error) => {
                _resolve()
            });
        })
   }

   private handleResize(){
        this.m_canvas.width = window.innerWidth;
        this.m_canvas.height = window.innerHeight - 125;
        this.redraw();   
        this.$forceUpdate()
   }

    private getScore(_userCoord, _answerCoord, _distance = false){
        const pointX = _userCoord['lng']
        const pointY = _userCoord['lat'] * -1;
        const responseX = _answerCoord[1];
        const responseY = _answerCoord[0] * -1;
        const y = responseX - pointX;
        const x = responseY - pointY;

        let lDistance = Math.sqrt(x * x + y * y);
        lDistance = Math.round(((lDistance * 617.3) / 34));
        if (_distance){
            return lDistance;
        }

        let lScore = this.k_maxDistance - lDistance + this.k_minDistance;
        if (lScore < 0){
            lScore = 0;
        }
        if (lScore > this.k_maxDistance){
            lScore = this.k_maxDistance;
        }

        let lPoints = 0;
        if (lScore > 0) {
            lPoints = (lScore * 5000) / this.k_maxDistance
        }
        return Math.round(lPoints);
    }

    private get getRefs() {
        if (this.$refs) {
            return this.$refs;
        } else{
            return {}
        }
    }

    private showRecap() {
        this.m_showRecap = true;
        this.m_isLoading = false;
        
        if (this.m_answerMaker) {
            this.m_map.removeLayer(this.m_answerMaker)
        }
        if (this.m_answerMaker) {
            this.m_map.removeLayer(this.m_marker)
        }
        if (this.m_answerMaker) {
            this.m_map.removeLayer(this.m_line)
        }

        let lScore = 0;
        for (let i = 0; i < data[this.m_dateKey].length; i++){
            const lMarker = new L.marker({lat: data[this.m_dateKey][i][0], lng: data[this.m_dateKey][i][1]}, {icon: this.m_icon})
            lMarker.addTo(this.m_map);

            const lUserMarker = new L.marker(this.m_history[this.m_dateKey][i]['coord']);
            lUserMarker.addTo(this.m_map);
            this.m_markerList.push(lMarker);
            this.m_markerList.push(lUserMarker);

            const latlngs: any[] = [] 
            latlngs.push(lMarker.getLatLng());
            latlngs.push(lUserMarker.getLatLng());
            const line = L.polyline(latlngs, {color: 'black'}).addTo(this.m_map);
            this.m_lineList.push(line);
            lScore += this.getScore(this.m_history[this.m_dateKey][i]['coord'], data[this.m_dateKey][i])
        }

        let lDate = new Date(new Date().getTime());
        const lDay = lDate.getUTCDate();
        const lMonth = lDate.getUTCMonth();
        const lYear = lDate.getUTCFullYear();
        lDate = new Date(Date.UTC(lYear, lMonth, lDay + 1, 0))
        lDate.setUTCDate(lDate.getDate() + 1);

        const lUTCDate =  new Date(lDate.toUTCString());
        this.updateCountdown(lUTCDate)
        this.m_menuKey++

        setInterval( () => {
            this.updateCountdown(lUTCDate)
        }, 1000);

        setTimeout( () => {
        this.m_map.invalidateSize(true);
            this.m_progressbarTotalScore = lScore;
            this.m_map.setView([-400, 400], 0);
        },300)
    }

    private updateCountdown(_date) {
        
        const lUserDate = new Date().getTime();

        const distance = _date.getTime() - lUserDate;

        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.m_countDown = this.addPadding(hours) + ":" + this.addPadding(minutes) + ":" + this.addPadding(seconds);

    }

    private addPadding(_number) {
        return (_number < 10) ? ("0" + _number) : _number;
    }

    private getDateKey() {

        let lDate = new Date();
        if (this.m_dateStr != "" && this.m_dateStr != undefined) {
            lDate = new Date(this.m_dateStr);
        }

        const lDay = lDate.getUTCDate();
        const lMonth = lDate.getUTCMonth() + 1;
        const lYear = lDate.getUTCFullYear();
        
        return lDay + "-" + lMonth + "-" + lYear
    }

    private get getPercentage() {
        let lPercentage = 0;
        if (this.m_score > 0){
            lPercentage = (this.getPoints / 5000) * 100
        }

        return lPercentage;
    }

    private get getTotalPercentage(){
        let lPercentage = 0;
        if (this.m_progressbarTotalScore > 0) {
            lPercentage = (this.m_progressbarTotalScore / 15000) * 100
        }

        return lPercentage;
    }

    private get getPoints() {
        let lPoints = 0;
        if (this.m_score > 0){
            lPoints = (this.m_score * 5000) / this.k_maxDistance
        }
        return Math.round(lPoints);
    }

    private checkDistance() {
        if (this.m_mode == "streak")
        {
            this.checkStreakAnwser();
        }
        else {
            const lCoord = this.m_marker.getLatLng()
            const pointX = lCoord.lng
            const pointY = lCoord.lat * -1;

            const responseX = this.m_answerCoord.lng;
            const responseY = this.m_answerCoord.lat * -1;

            this.m_answerMaker.addTo(this.m_map);

            const y = responseX - pointX;
            const x = responseY - pointY;

            let lDistance = Math.sqrt(x * x + y * y);
            lDistance = Math.round(((lDistance * 617.3) / 34));
            this.m_showResult = true;
            this.m_showMap = true;

            this.m_score = 0;
            this.$nextTick(() => {
                this.m_distance = lDistance;

                this.m_score = this.k_maxDistance - this.m_distance + this.k_minDistance;
                if (this.m_score < 0){
                    this.m_score = 0;
                }
                if (this.m_score > this.k_maxDistance){
                    this.m_score = this.k_maxDistance;
                }

                this.m_totalScore += this.getPoints;

                if (this.m_history[this.getDateKey()] == undefined) {
                    this.m_history[this.getDateKey()] = [{ coord: lCoord }]
                }
                else{
                    this.m_history[this.getDateKey()][this.m_currentMapIndex] = { coord: lCoord };
                }

                localStorage.setItem('history', JSON.stringify(this.m_history))
                this.m_map.invalidateSize(true);
                this.drawLine()
            })
        }
    }

  
    private addMarker(_event) {
        if (this.m_showResult || !this.isCoordinateValide(_event.latlng) || this.m_showRecap) {
            return false;
        }
        if (this.m_marker != undefined) {
            this.m_map.removeLayer(this.m_marker)
            if (this.m_line){
                this.m_map.removeLayer(this.m_line)
            }
        }
        this.m_marker = new L.marker(_event.latlng);
        this.m_markerList.push(this.m_marker);
        this.m_marker.addTo(this.m_map);
        this.m_hasPicked = true;
    }

    private addToClipboard(){
        let lText ="";
        if (this.m_mode == "streak"){
            lText = "I made a streak of " + this.m_streakCount + " islands, try to beat me!\n"
        }
        else{
            lText = "I made "+ this.m_progressbarTotalScore.toLocaleString() + " points today, try to beat me !\n"
                + "1] " + this.getScore(this.m_history[this.m_dateKey][0]['coord'], data[this.m_dateKey][0]) + " points (" + this.getScore(this.m_history[this.m_dateKey][0]['coord'], data[this.m_dateKey][0], true)+ " m)\n"
                + "2] " + this.getScore(this.m_history[this.m_dateKey][1]['coord'], data[this.m_dateKey][1]) + " points (" + this.getScore(this.m_history[this.m_dateKey][1]['coord'], data[this.m_dateKey][1], true)+ " m)\n"
                + "3] " + this.getScore(this.m_history[this.m_dateKey][2]['coord'], data[this.m_dateKey][2]) + " points (" + this.getScore(this.m_history[this.m_dateKey][2]['coord'], data[this.m_dateKey][2], true)+ " m)\n"
       
        }
        lText += "#SeaOfThievesGuessr \n\nhttps://seaofthievesguessr.com/"
        navigator.clipboard.writeText(lText);

        this.m_showClipboard = true;
        setTimeout(() => {
            this.m_showClipboard = false
        },700)
    }

    private getTwitterLink(){
        let lBaseUrl = "https://twitter.com/intent/tweet?text=I made " ;
        if (this.m_mode == "streak"){
            lBaseUrl += "a streak of " + this.m_streakCount + " islands, try to beat me!"+ encodeURI("\n");
        }
        else {
            lBaseUrl += this.m_progressbarTotalScore.toLocaleString() + " points today, try to beat me !" + encodeURI("\n") 
        + "1] " + this.getScore(this.m_history[this.m_dateKey][0]['coord'], data[this.m_dateKey][0]) + " points (" + this.getScore(this.m_history[this.m_dateKey][0]['coord'], data[this.m_dateKey][0], true)+ " m)"+ encodeURI("\n") 
        + "2] " + this.getScore(this.m_history[this.m_dateKey][1]['coord'], data[this.m_dateKey][1]) + " points (" + this.getScore(this.m_history[this.m_dateKey][1]['coord'], data[this.m_dateKey][1], true)+ " m)"+ encodeURI("\n") 
        + "3] " + this.getScore(this.m_history[this.m_dateKey][2]['coord'], data[this.m_dateKey][2]) + " points (" + this.getScore(this.m_history[this.m_dateKey][2]['coord'], data[this.m_dateKey][2], true)+ " m)"+ encodeURI("\n") 
        }
        
        lBaseUrl += "%23SeaOfThievesGuessr " +encodeURI("\n\n&url=https://seaofthievesguessr.com/")
        return lBaseUrl;
    }

    public get getCurrentBadge(){
        return this.m_badgeList.filter(_elem => {
           return (_elem['value'] == this.$store.state.badge)
        })[0]
    }

    private addBadge(_badge) {        
        this.m_badgeShow = localStorage.getItem('badges_show')
        if (localStorage['badges_show'] == null || localStorage['badges_show'] == undefined) {
            localStorage.setItem('badges_show', JSON.stringify([]))
            this.m_badgeShow = []
        }
        else{
            this.m_badgeShow = JSON.parse(this.m_badgeShow);
        }
        this.m_badgeShow.push(_badge)
        localStorage.setItem('badges_show', JSON.stringify(this.m_badgeShow))
        this.$store.dispatch("setBadge", "");
        this.m_menuKey++;
    }

    @Watch("m_increaseSize")
    private watchZoom() {
        if (this.m_map){

            if (this.m_increaseSize){
                document.getElementsByClassName('leaflet-control-zoom')[0].classList.remove("hide");
            }
            else{
                document.getElementsByClassName('leaflet-control-zoom')[0].classList.add("hide");
            }
            if (this.m_mode == "daily" && this.m_line && !this.m_showMap) {
                setTimeout(() => {
                    this.m_map.invalidateSize(true);
                    this.m_map.fitBounds(this.m_line.getBounds());
                }, 200);
            }
        }
    }
    
    private m_badgeList = [
        {
            "name": "First game",
            "displayName": "Finish your first game",
            "value": "first_games",
            "unlock": "getGameCount",
            "goal": 1
        },
        {
            "name": "10 games",
            "displayName": "Finish 10 games",
            "value": "ten_games",
            "unlock": "getGameCount",
            "goal": 10
        },
        {
            "name": "50 games",
            "displayName": "Finish 50 games",
            "value": "fifty_games",
            "unlock": "getGameCount",
            "goal": 50
        },
        {
            "name": "100 games",
            "displayName": "Finish 100 games",
            "value": "hundred_games",
            "unlock": "getGameCount",
            "goal": 100
        },
        {
            "name": "3 streak games",
            "displayName": "Finish 3 consecutive games",
            "value": "3row_games",
            "unlock": "getRowGameCount",
            "goal": 3
        },
        {
            "name": "7 streak games",
            "displayName": "Finish 7 consecutive games",
            "value": "7row_games",
            "unlock": "getRowGameCount",
            "goal": 7
        },
        {
            "name": "30 streak games",
            "displayName": "Finish 30 consecutive games",
            "value": "30row_games",
            "unlock": "getRowGameCount",
            "goal": 30
        },
        {
            "name": "50 streak games",
            "displayName": "Finish 50 consecutive games",
            "value": "50row_games",
            "unlock": "getRowGameCount",
            "goal": 50
        },
        {
            "name": "Perfect streak",
            "displayName": "Score 15 000 points",
            "value": "1perfect",
            "unlock": "getPerfectGamesCount",
            "goal": 1
        },
        {
            "name": "5 Perfect streak",
            "displayName": "Score 15 000 points 5 times",
            "value": "5perfect",
            "unlock": "getPerfectGamesCount",
            "goal": 5
        },
        {
            "name": "10 Perfect streak",
            "displayName": "Score 15 000 points 10 times",
            "value": "10perfect",
            "unlock": "getPerfectGamesCount",
            "goal": 10
        },
        {
            "name": "20 Perfect streak",
            "displayName": "Score 15 000 points 20 times",
            "value": "20perfect",
            "unlock": "getPerfectGamesCount",
            "goal": 20
        },
        {
            "name": "Null game",
            "displayName": "Score a total of 0 point",
            "value": "1null",
            "unlock": "getNullGamesCount",
            "goal": 1
        },
        {
            "name": "Close game",
            "displayName": "Score a total of 14999 points",
            "value": "1close",
            "unlock": "getCloseGamesCount",
            "goal": 1
        },
        {
            "name": "Less than 10 meter",
            "displayName": "Make a total of less than 10 meter in a daily game",
            "value": "10mclose",
            "unlock": "getLessThan10Meter",
            "goal": 1
        },
        {
            "name": "Perfect guess",
            "displayName": "Score 0 meter on a single shot",
            "value": "0mclose",
            "unlock": "get0Meter",
            "goal": 1
        },
        {
            "name": "Expert of the Wilds",
            "displayName": "Score 5000 points in the Wilds",
            "value": "wild_1",
            "unlock": "getWild",
            "goal": 1
        },
        {
            "name": "Expert of the Wilds",
            "displayName": "Score 5000 points in the Wilds 10 times",
            "value": "wild_2",
            "unlock": "getWild",
            "goal": 10
        },
        {
            "name": "Expert of the Wilds",
            "displayName": "Score 5000 points in the Wilds 25 times",
            "value": "wild_3",
            "unlock": "getWild",
            "goal": 25
        },
        {
            "name": "Expert of the Wilds",
            "displayName": "Score 5000 points in the Wilds 50 times",
            "value": "wild_4",
            "unlock": "getWild",
            "goal": 50
        },
        {
            "name": "Expert of the Shore of Plenty",
            "displayName": "Score 5000 points in the Shore of Plenty",
            "value": "plenty_1",
            "unlock": "getPlenty",
            "goal": 1
        },
        {
            "name": "Expert of the Shore of Plenty",
            "displayName": "Score 5000 points in the Shore of Plenty 10 times",
            "value": "plenty_2",
            "unlock": "getPlenty",
            "goal": 10
        },
        {
            "name": "Expert of the Shore of Plenty",
            "displayName": "Score 5000 points in the Shore of Plenty 25 times",
            "value": "plenty_3",
            "unlock": "getPlenty",
            "goal": 25
        },
        {
            "name": "Expert of the Shore of Plenty",
            "displayName": "Score 5000 points in the Shore of Plenty 50 times",
            "value": "plenty_4",
            "unlock": "getPlenty",
            "goal": 50
        },
        {
            "name": "Expert of the Ancient Isles",
            "displayName": "Score 5000 points in the Ancient Isles",
            "value": "ancient_1",
            "unlock": "getAncient",
            "goal": 1
        },
        {
            "name": "Expert of the Ancient Isles",
            "displayName": "Score 5000 points in the Ancient Isles 10 times",
            "value": "ancient_2",
            "unlock": "getAncient",
            "goal": 10
        },
        {
            "name": "Expert of the Ancient Isles",
            "displayName": "Score 5000 points in the Ancient Isles 25 times",
            "value": "ancient_3",
            "unlock": "getAncient",
            "goal": 25
        },
        {
            "name": "Expert of the Ancient Isles",
            "displayName": "Score 5000 points in the Ancient Isles 50 times",
            "value": "ancient_4",
            "unlock": "getAncient",
            "goal": 50
        },
        {
            "name": "Expert of the Devil's Roar",
            "displayName": "Score 5000 points in the Devil's Roar",
            "value": "roar_1",
            "unlock": "getRoar",
            "goal": 1
        },
        {
            "name": "Expert of the Devil's Roar",
            "displayName": "Score 5000 points in the Devil's Roar 7 times",
            "value": "roar_2",
            "unlock": "getRoar",
            "goal": 7
        },
        {
            "name": "Expert of the Devil's Roar",
            "displayName": "Score 5000 points in the Devil's Roar 15 times",
            "value": "roar_3",
            "unlock": "getRoar",
            "goal": 15
        },
        {
            "name": "Expert of the Devil's Roar",
            "displayName": "Score 5000 points in the Devil's Roar 30 times",
            "value": "roar_4",
            "unlock": "getRoar",
            "goal": 30
        },
        {
            "name": "5 Streak",
            "displayName": "Make a streak of 5 islands",
            "value": "streak_5",
            "unlock": "getStreak",
            "goal": "5"
        },
        {
            "name": "10 Streak",
            "displayName": "Make a streak of 10 islands",
            "value": "streak_10",
            "unlock": "getStreak",
            "goal": "10"
        },
        {
            "name": "25 Streak",
            "displayName": "Make a streak of 25 islands",
            "value": "streak_25",
            "unlock": "getStreak",
            "goal": "25"
        },
        {
            "name": "50 Streak",
            "displayName": "Make a streak of 50 islands",
            "value": "streak_50",
            "unlock": "getStreak",
            "goal": "50"
        },
        {
            "name": "69 Streak",
            "displayName": "Make a streak of 69 islands (nice)",
            "value": "streak_69",
            "unlock": "getStreak",
            "goal": "69"
        },
        {
            "name": "100 Streak",
            "displayName": "Make a streak of 100 islands",
            "value": "streak_100",
            "unlock": "getStreak",
            "goal": "100"
        }
    ]
}
